import React, { useEffect } from "react";

import { graphql } from "gatsby";

import styled from "@emotion/styled";

import CheckoutPage from "../../../../components/pages/Pellets/CheckoutPage";
import PelletsDataProvider from "../../../../providers/PelletsDataProvider";
import { sessionStorage } from "../../../../utils/StorageHandler";
import { getParentReferrerUrl } from "../../../../utils/webViewRedirect";

//Add some bottom padding because of the missing Footer:
const Container = styled.div`
    padding-bottom: 5em;
`;

const HeizoelCheckoutWebview = (props) => {
    //Overwrite the Referrer and Params when an Embedding is used.
    useEffect(() => {
        sessionStorage.setItem("app:parameter", "Embedded WebView");
        sessionStorage.setItem("app:referrer", getParentReferrerUrl());
    }, []);

    return (
        <PelletsDataProvider>
            <Container>
                <CheckoutPage {...props} />
            </Container>
        </PelletsDataProvider>
    );
};

export default HeizoelCheckoutWebview;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "holzpellets" }) {
            ...Page
        }
    }
`;
