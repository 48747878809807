import React, { useContext, useEffect } from "react";

import { css, useTheme } from "@emotion/react";

import checkoutSteps from "../../../../providers/PelCheckoutSteps";
import PelletsCheckoutDataProvider, {
    DataContext,
} from "../../../../providers/PelletsCheckoutDataProvider";
import {
    storageLocal as storage,
    storageSession as session,
} from "../../../../utils/StorageHandler";
import resolveWebView from "../../../../utils/webViewRedirect";
import Container from "../../../shared/Container";
import { navigate } from "../../../shared/LinkComponent";
import OrderStepper from "../../../shared/OrderStepper";
import useSnackbar from "../../../shared/Snackbar/store";
import BottomBar from "./BottomBar";
import StepButtons from "./StepButtons";

export const CheckoutPage = () => {
    const { notify } = useSnackbar();
    const theme = useTheme();
    const dataContext = useContext(DataContext);
    const { steps, currentStepIndex } = dataContext.data;
    const prices = dataContext.actions.getPrices();
    const currentStepComponent = checkoutSteps[currentStepIndex].component;
    const currentStep = checkoutSteps[currentStepIndex].step;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const crossSelling = checkoutSteps[currentStepIndex].crossSelling
        ? true
        : false;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStepIndex]);

    useEffect(() => {
        const checkForSession = async () => {
            /**
             * When a user calls a checkout page without session, he can place an order
             * without valid data, causing errors.
             * If the user has no session, while he needs one, redirect him back to the calculator.
             */
            if (
                storage.getBoolean("pel:needs-session") === true &&
                session.getJson("pel:checkout", null) === null
            ) {
                await navigate(resolveWebView("/holzpellets/"));
                void notify(
                    "Sitzung abgelaufen",
                    "Die Sitzung dieser Bestellung ist abgelaufen. Starte bitte eine Neue.",
                    "default",
                    { timeout: 10000 }
                );
            }
        };

        void checkForSession();
    }, []);

    const newStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;

    return (
        <>
            <Container slim css={newStyles}>
                {currentStep !== null && (
                    <OrderStepper steps={steps} currentStep={currentStep} />
                )}
                {currentStepComponent}
                {currentStepIndex < checkoutSteps.length - 1 && <StepButtons />}
            </Container>
            {prices && <BottomBar />}
        </>
    );
};

const WithProvider = ({ checkoutStep }) => {
    return (
        <PelletsCheckoutDataProvider {...{ checkoutStep }}>
            <CheckoutPage />
        </PelletsCheckoutDataProvider>
    );
};

export default WithProvider;
