import React, { useContext } from "react";

import { DataContext } from "../../../../../providers/PelletsCheckoutDataProvider";
import { storageLocal as storage } from "../../../../../utils/StorageHandler";
import OrderBottomBar from "../../../../shared/OrderBottomBar";

const BottomBar = () => {
    const dataContext = useContext(DataContext);
    const product = storage.get("pel:order-product-type");

    const prices = dataContext.actions.getPrices();

    const getDefaultAmount = () => {
        return product === "loose"
            ? "1t"
            : product === "bags"
            ? " Palette"
            : " Big-Bag";
    };

    if (!prices) {
        return null;
    }

    return (
        <>
            <OrderBottomBar
                price={`${prices.priceGross} €`}
                leftText={`Preis ${getDefaultAmount()} inkl. MwSt.`}
                totalPrice={`${prices.priceTotalGross} €`}
            />
        </>
    );
};

export default BottomBar;
