import React, { useContext } from "react";

import { DataContext } from "../../../../../providers/PelletsCheckoutDataProvider";
import resolveWebView from "../../../../../utils/webViewRedirect";
import { navigate } from "../../../../shared/LinkComponent";
import NewButton from "../../../../shared/NewForms/Button";
import Section from "../../../../shared/Section";
import TextAlign from "../../../../shared/TextAlign";
import * as styles from "./style.module.scss";

const StepButtons = () => {
    const dataContext = useContext(DataContext);
    const { currentStepIndex, editMode } = dataContext.data;
    const {
        previousStepVisible,
        nextStep,
        previousStep,
        setStepButtonAction,
        setEditMode,
    } = dataContext.actions;

    const next = () => {
        setStepButtonAction("next");
        if (editMode) {
            setEditMode(false);
            void navigate(resolveWebView("/pellets/bestellung/uebersicht"));
        } else {
            nextStep();
        }
    };

    const previous = () => {
        previousStep();
        setStepButtonAction("previous");
        if (editMode) {
            setEditMode(false);
            void navigate(resolveWebView("/pellets/bestellung/uebersicht"));
        } else {
            previousStep();
        }
    };

    return (
        <Section withMargin>
            {currentStepIndex < 3 ? (
                <div className="columns is-mobile ">
                    <div className="column">
                        {previousStepVisible() && (
                            <NewButton color="grey" onClick={previous}>
                                Zurück
                            </NewButton>
                        )}
                    </div>
                    <TextAlign align="right" className="column">
                        <NewButton onClick={next}>
                            {editMode ? "Speichern" : "Weiter"}
                        </NewButton>
                    </TextAlign>
                </div>
            ) : (
                <div className={styles.finalWrapper}>
                    <NewButton color="green" onClick={next}>
                        Kostenpflichtig bestellen
                    </NewButton>
                </div>
            )}
        </Section>
    );
};

export default StepButtons;
